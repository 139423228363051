import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { Section, SectionWrapper, Text, Title } from "../../core/commonExports"
import { Button } from "../../core/Button"

const FooterSection = ({
  pageName,
  handleGetQuoteClick,
  style={},
  mobileStyles={background: "#fff"},
  desktopStyles={background: "#F6F9FF"}
}) => {
  const isGLP7 = pageName === "new GLP7"
  const leftAlignStyle = {textAlign: "left"}
  
  return (
    <Section mobileStyles={mobileStyles} desktopStyles={desktopStyles} style={style} className="position-relative">
      <SectionWrapper>
        <Container style={{width: '100%', position: 'relative', alignItems: 'center'}}>
          <HeadingContainer style={{marginLeft: '0', maxWidth: '430px'}}>
            {isGLP7 ? (
              <Text fontSize="22px" lineHeight="23px" mfontSize="14px" mlineHeight="23px" color="#244BCE" fontWeight="700" style={{...leftAlignStyle, alignSelf: "flex-start"}} desktopStyles={{marginBottom: "24px"}} mobileStyles={{marginBottom: "6px"}}>
                Save big! Don’t miss out on this offer.
              </Text>
            ) : null}
            <Title fontSize="36px" mfontSize="24px" style={isGLP7 ? {...leftAlignStyle, marginBottom: '40px'} : { marginBottom: '40px' }}>
              Secure your business property against theft, fire, flood and 10+ threats <span style={{color: "rgb(255, 147, 79)"}}>NOW!</span>
            </Title>
            <div className="on-desktop">
              <Button label="Get a Quote" uppercaseLabel={false} type="flatdesign" wide onClick={handleGetQuoteClick} />
            </div>
          </HeadingContainer>
          <div style={{width: "min(570px, 100%)", height: "auto"}}>
            <StaticImage
              src="../../../assets/images/campaigns/glp6-footer-section-img.webp"
              alt="secure your business"
              loading='lazy'
              width={570}
              height={510}
            />
          </div>
          <div className="on-mobile">
            <Button label="Get a Quote" uppercaseLabel={false} type="flatdesign" wide style={{margin: 'auto', marginTop: '2rem'}} onClick={handleGetQuoteClick} />
          </div>
        </Container>
      </SectionWrapper>
    </Section>
  )
}

const HeadingContainer = styled.div`
  margin-left: 150px;
  width: 100%;

  @media screen and (max-width: 768px) {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Container = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    margin: 20px 0;
    flex-direction: ${({ reverse }) =>
      `${reverse ? "column-reverse" : "column"}`};
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export default FooterSection